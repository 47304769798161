@import "~antd/dist/antd.less";


.ant-table-row-level-1.reconAcctWithTable {
  background-color: #F5F5F5;
}

.viewMember {
  .ant-table-thead {
    font-size: 12px;
  }
  .ant-table-tbody{
    font-size: 12px;
      .ant-badge-status-text {
        font-size: 12px;
      }
  }
}

.reconAcctWithTable > td {
  font-size: 12px;
  & .details-numbers {
      padding-left:50px;
  }
}

.reconAcctWithTable > td:first-child {
  padding-left: 30px;
  &:before {
    content: "- ";
  }
}

.downlineInviteAppr .cardDetails {
  font-size: 11px;
}

.downlineInviteAppr #downlineInviteList thead.ant-table-thead > tr:nth-child(1) {
  display: none;
}

.downlineInviteAppr #ref-comm {
  background-color: white;
}

.downlineInviteAppr #ref-comm-tab-1{
  margin-left:30px;
}

.report-daily-sales-tab .ant-tabs-tab {
  margin-left: 15pt;
}

#page-operation-report .operationsList .ant-table {
    font-size:10px;
}

.table-12-cols .ant-table {
  font-size:10px;  //to replace --> page-operation-report .operationsList .ant-table
}

.divider {
  border:2px solid lightgray;
}

.cs-report-monitor {
  margin-top:"15px";
  margin-left:"5px";
  width: "auto";

  .ant-table-placeholder {
    background-color: #fafafa;
  }

  .ant-table .ant-table-cell {
    padding:6px 6px;
  }

  .monitor-no-header .ant-table-thead{
    display: none;
  }

  .monitorHeader {
    margin: 0;
    font-weight: bolder;
    box-sizing: border-box;
    border-collapse: collapse;
    color : #3a3a3a;
  }

  .monitorAlert {
    color:red;
    font-weight:bolder;
    margin-left:0.2rem;
  }

  .monitorNormal {
    color:green;
    font-weight:bolder;
    margin-left:0.2rem;
  }
}

.warning {
  font-weight: bolder;
  color :red;
}


.smallFont {
  font-size: 9pt;
}

.nowrap {
  white-space: nowrap;
}

.ml-15pc {
  margin-left:15%;
}

.ant-tooltip-inner {
  width: max-content;
}

.dynamic-delete-button {
  margin: 0 8px;
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: red;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: red;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.logoTitle {
    color:white;
    font-family:Arial, Helvetica, sans-serif;
}

.gameUserInfo {
  display: table;

  .col1{
    display:table-cell;
    width :120px;
  }

  .col2{
    display:table-cell;
    width :150px;
    margin-left: 5px;
  }

  .col3{
    display:table-cell;
    width :30px;
    margin-left: 5px;
  }
}

//Dnavigation
.Dheader {
  padding: 0px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  height: 80px;
  align-items: center;
}

.DRightHeaderBtn {
  margin-right: 30px;
  border: none;
  background-color: #001529;
  display: flex;
  align-items: center;
}

.DRightHeaderIcon {
  color: #ffffff;
  font-size: 30px;
  padding-right: 10px;
}

.DLogoutTxt {
  color: #ffffff;
  font-size: 25px;
}

.DRightTopDropdownMenu {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

//Footer
.ant-layout-footer {
  text-align: center;
}

//content
.DContentContainer {
  margin: 30px;
  background-color: #ffffff;
}

//EditableCell

.EditableCell {
  margin: 0px;
}

//getColumnSearchProps

.getColumnSearchProps {
  padding: 8px;
}

.getColumnSearchInput {
  width: 188px;
  margin-bottom: 8px;
  display: block;
}

.getColumnSearchBtn {
  width: 90px;
  margin-right: 8px;
}

.getColumnResetBtn {
  width: 90px;
}

.getColumnFilterBtn {
  color: #1890ff;
}

.getColumnHighlighter {
  background-color: #ffc069;
  padding: 0px;
}

//columnEditing
.editingContainer {
  flex-direction: row;
  display: flex;
  justify-content: space-evenly;
}

.editingInput {
  width: auto;
  text-align: center;
}

// Bank Balance Table Content 
.bankBalance .ant-table-tbody .ant-table-cell {
  font-size: 12px;
}

.cs_dashboard table .ant-table-cell {
  font-size: 11px;
}

.cs_dailyTrxnStat table .ant-table-cell {
  font-size: 11px;
}

//Report Downline
.reportDownline div,
.reportDownline [class^='ant-badge'],
.reportDownline .ant-btn,
.reportDownline div.ant-table-content {
  font-size:10px;
}

.manualDeposit .status-approved-dropdown .ant-dropdown-trigger {
  margin-left: -16px;
  margin-right:-16px;
}

/*
* RootCompanyManagement
*/

.RlandContainer {
  padding: 30px;
  background-color: #ffffff;
}

.RlandHeaderContainer {
  margin: 20px;
  margin-left: 0px;
}

.RlandHeaderTxtContainer {
  text-align: center;
}

.RlandHeaderTxt {
  font-size: 25px;
}

.RlandBtnContainer {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.RAddCompanyContainer {
  text-align: right;
}

.RAddCompanyBtn {
  margin-right: 10px;
}

/*
*mobile
*/

//Mnavigation
.rightTopDropdownMenu {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #ffffff;
  height: 50px;
  border-bottom: 1px solid gray;
}

.rightTopMenuIcon {
  color: #000000;
  margin-right: 10px;
}

.logoutIconStyle {
  color: #000000;
  margin-right: 10px;
}

.logoutTxt {
  color: #000000;
}

.headerDropdownMenu {
  background-color: rgba(0, 0, 0, 0.45);
  height: 100vh;
  width: 100vw;
  margin-top: 80px;
}

.dropdownContainer {
  margin-top: 80px;
}
.Mheader {
  z-index: 10002;
  padding: 0px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  height: 80px;
  position: fixed;
  margin-bottom: 100px;
  width: 100vw;
}

.logoContainer {
  align-items: center;
  display: flex;
}

.logo {
  max-width: 40%;
  margin-left: 30px;
}

.MRightHeaderBtn {
  background-color: #001529;
  border: none;
  display: flex;
  align-items: center;
  height: 80px;
  width: 80px;
  margin-right: 15px;
}

.logoutLogo {
  color: #ffffff;
  font-size: 30px;
}

//MContent

.MContentContainer {
  margin-top: 80px;
  background-color: #ffffff;
}

//list description style
.decStyle {
  display: flex;
  flex-direction: row;
}

.decLable {
  width: 140px;
}

.decTxt {
  border: none;
  width: 120px;
}

.decBottomBtn {
  display: flex;
  flex-direction: row;
  height: 50px;
  align-items: flex-end;
  justify-content: flex-end;
}

.decOkBtn {
  margin-right: 10px;
  border-color: #000000;
  background-color: #ffffff;
  color: #000000;
  width: 80px;
}

.decEditBtn {
  width: 80px;
}

.cardContainer {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.cardStatus {
  height: 80px;
  width: 10px;
  margin-bottom: 30px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.cardStyle {
  display: flex;
  flex-direction: row;
  width: 240px;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  height: 70px;
  align-items: center;
  box-shadow: 5px 8px 5px 5px rgba(208, 216, 243, 0.6);
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #ffffff;
  color: #000000;
  margin-bottom: 30px;
  border: 1px solid gray;
  border-left: 0px;
}

//RootCompanyManagement

.RportraitTitle {
  text-align: center;
  font-size: 25px;
  margin-top: 35px;
}

.RportraitPageContainer {
  flex-direction: column;
  display: flex;
  align-items: center;
}

.RportraitBtnContainer {
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100vw;
  padding: 10px;
}

.RportraitAddCompanyBtn {
  border-radius: 10px;
  width: 100vw;
}

.por ::-webkit-scrollbar {
  display: none; //hide scrollbars
}

.App {
  text-align: center;
  background-color: rgb(238, 238, 238);
  height: 100vh;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.site-layout .site-layout-background {
  background: #fff;
}

.ant-menu {
  // height: 100vh;
  overflow: auto; //enable site menu scroll
}

.editable-row-operations a {
  margin-right: 8px;
}

// .ant-table-pagination-right {
//   float: left;
// }

.ant-modal-close-x {
  display: none;
}

.login-form {
  max-width: 500px;
}

.login-form-button {
  width: 100%;
}

.loginBody {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  background-color: rgba(0, 21, 41, 1);
}

.loginLogo {
  max-width: 500px;
  width: 100%;
  text-align: center;
  margin-bottom: 50px;
  font-size: 70px;
}

.loginFieldBg {
  max-width: 500px;
  width: 100%;
  text-align: center;
  // background-color: rgba(22, 20, 20, 0.2);
  background-color: rgba(255, 255, 255, 0.1);
}

.ant-modal-title {
  text-align: center;
}

.password {
  -webkit-text-security: disc;
}

.logoutBorder {
  border-top: 1px solid #eeebeb;
}

.ant-statistic-title {
  font-size: 18px;
}

.ant-layout-header {
  padding: 0 0px;
}

.ant-card-body {
  padding: 5px;
}

.ant-tag {
  margin-right: 0;
}

/*
* Galaxy Fold
*/
@media only screen and (min-device-width: 250px) and (max-device-width: 299px) {
  .footerTxt {
    font-size: 10px;
  }
  .cardStatus {
    height: 70px;
  }

  .ant-layout-sider {
    z-index: 10001;
    position: fixed;
    background-color: "#001529";
    min-height: 100%;
    top: 0;
    left: 0;
  }

  .menuBackground {
    background-color: rgba(0, 0, 0, 0.45);
    width: 100%;
    height: 100vh;
    z-index: 10000;
    position: fixed;
    display: none;
    top: 0;
    left: 0;
  }
}

/*
* Iphone 5/SE
*/
@media only screen and (min-device-width: 300px) and (max-device-width: 399px) {
  .cardStyle {
    width: 280px;
  }

  .cardStatus {
    height: 70px;
  }

  .ant-layout-sider {
    z-index: 10001;
    position: fixed;
    background-color: "#001529";
    min-height: 100%;
    top: 0;
    left: 0;
  }
  .menuBackground {
    background-color: rgba(0, 0, 0, 0.45);
    width: 100%;
    height: 100vh;
    z-index: 10000;
    position: fixed;
    display: none;
    top: 0;
    left: 0;
  }

  .footerTxt {
    font-size: 12px;
  }
}

@media only screen and (min-device-width: 360px) and (max-device-width: 499px) {
  .cardStyle {
    width: 320px;
  }

  .cardStatus {
    height: 70px;
  }

  .ant-layout-sider {
    z-index: 10001;
    position: fixed;
    background-color: "#001529";
    min-height: 100%;
    top: 0;
    left: 0;
  }
  .menuBackground {
    background-color: rgba(0, 0, 0, 0.45);
    width: 100%;
    height: 100vh;
    z-index: 10000;
    position: fixed;
    display: none;
    top: 0;
    left: 0;
  }

  .loginBody {
    padding: 20px;
  }

  .ant-page-header {
    padding-left: 0;
    padding-bottom: 30;
    padding-right: 0;
    padding-top: 30;
  }

  .ant-breadcrumb {
    margin-left: 30px;
  }
  .ant-dropdown {
    width: 100% !important;
  }
}

@media only screen and (min-device-width: 500px) and (max-device-width: 699px) {
  .cardStyle {
    width: 500px;
  }

  .cardStatus {
    height: 70px;
  }
}

@media only screen and (min-device-width: 700px) and (max-device-width: 999px) {
  .cardStyle {
    width: 700px;
    height: 100px;
  }

  .cardStatus {
    height: 100px;
  }

  .ant-layout-sider {
    z-index: 10001;
    position: fixed;
    background-color: "#001529";
    min-height: 100%;
    top: 0;
    left: 0;
  }
  .menuBackground {
    background-color: rgba(0, 0, 0, 0.45);
    width: 100%;
    height: 100vh;
    z-index: 10000;
    position: fixed;
    display: none;
    top: 0;
    left: 0;
  }
}

/*
* Lumia 550
*/
@media only screen and (min-device-width: 600px) and (max-device-width: 680px) {
  .cardStyle {
    height: 70px;
    width: 600px;
  }

  .cardStatus {
    height: 70px;
    width: 10px;
    box-shadow: 5px 8px 5px 5px rgba(208, 216, 243, 0.6);
    margin-bottom: 30px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
}

/*
* Ipad Pro
*/
@media only screen and (min-device-width: 1000px) and (max-device-width: 2000px) {
  .ant-layout-sider {
    z-index: 10001;
    position: fixed;
    background-color: "#001529";
    min-height: 100%;
    top: 0;
    left: 0;
  }
  .cardStyle {
    width: 950px;
    height: 100px;
  }

  .cardStatus {
    height: 100px;
  }

  .menuBackground {
    background-color: rgba(0, 0, 0, 0.45);
    width: 100%;
    height: 100vh;
    z-index: 10000;
    position: fixed;
    display: none;
    top: 0;
    left: 0;
  }
}

@primary-color: #1DA57A;